@font-face {
  font-family: 'digital-7';
  src: url('./assets/font/digital-7.ttf');
}

.App {
  text-align: center;
}

body {
  margin: 0px;
  height: 100vh;
  /* background-color: #f5f5f8; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.tooltip-class {
  max-width: 200px;
}

.tooltip {
  background: #f5f5f8;
}

.apexcharts-xaxistooltip {
  display: none;
}

.apexcharts-yaxistooltip {
  background: #f5f5f8 !important;
  border: none !important;
  box-shadow: none;
  font-size: 12px;
  font-weight: bold;
  color: #212d63;
}

.apexcharts-yaxistooltip:after {
  display: none;
}

.apexcharts-yaxistooltip:before {
  display: none;
}
.apexcharts-tooltip {
  background: #f5f5f8 !important;
  border: none !important;
  box-shadow: none;
  font-size: 12px;
  font-weight: bold;
  color: #212d63;
}

.Toastify__toast--warning {
  background-color: #f89406;
  opacity: 0.9;
}

.btn-press:hover {
  transform: scale(1.1);
}

.btn-press:active {
  transform: scale(1.1);
}

.btn-press:focus {
  transform: scale(1.1);
}

.pagination {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 30px;
  width: fit-content;
}

@media (max-width: 520px) {
  .pagination {
    width: auto;
  }
}

.pagination li {
  width: 24px;
  height: 24px;
  margin: 0 8px;
  text-align: center;
}

.pagination a {
  color: #2dc48f;
  font-weight: 800;
  outline: none;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active {
  background: #2dc48f;
  border-radius: 100%;
}
.pagination__link--active a {
  color: #fff;
  font-weight: 800;
  outline: none;
}

.styled-toast {
  border-radius: 4px;
  min-width: 250px;
}

.styled-toast-body {
  min-height: 40px;
  flex: unset;
  border-radius: 9px;
  background: inherit;
  background-image: linear-gradient(105deg, #41dea2 4%, #ba78f0);
  color: white;
  font-size: 13px;
  font-weight: 800;
  margin: 0;
}

.styled-toast-dark-body {
  padding: 0;
  min-height: 40px;
  flex: unset;
  border-radius: 4px;
  color: white;
  opacity: 1;
  font-size: 13px;
  font-weight: 800;
  box-shadow: 0 0 10px 0 rgba(65, 222, 162, 0.31),
    inset 2px 2px 4px 0 rgba(10, 19, 16, 0.2);
  border-style: solid;
  border-width: 1.5px;
  border-image-source: linear-gradient(106deg, #41dea2 4%, #ba78f0);
  background-image: linear-gradient(to right, #092d32, #052228),
    linear-gradient(106deg, #41dea2 4%, #ba78f0);
  background-origin: border-box;
  background-clip: content-box, border-box;
  transition: all 0.3s ease-out;
}

.Toastify__close-button {
  color: white !important;
  opacity: 1 !important;
}

.Toastify__toast {
  background-color: inherit !important;
  box-shadow: none !important;
  margin-bottom: 0;
  justify-content: flex-end;
}

.cell-sort-active {
  color: #212d63 !important;
  opacity: 1 !important;
}

.dark-mode-cell-sort-active {
  color: #ffffff !important;
  opacity: 1 !important;
}

.token-container {
  overflow-x: hidden !important;
}
.token-container::-webkit-scrollbar {
  width: 4px;
}
.token-container::-webkit-scrollbar-thumb {
  background-color: rgba(33, 45, 99, 0.12);
  border-radius: 10px;
}

.Toastify__toast-container--top-right {
  top: 6em !important;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -10000px;
  }
}

@keyframes swell {
  0%,
  100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

.helper-tooltip {
  opacity: 1 !important;
  padding: 1px !important;
  border-radius: 10px !important;
  box-shadow: 0 6px 21px 0 #d1d9e6;
  background-image: linear-gradient(
      to bottom,
      rgba(245, 245, 248, 0.5),
      rgba(245, 245, 248, 0.5)
    ),
    linear-gradient(124deg, #41dea2 3%, #ba78f0) !important;
  background-origin: border-box !important;
  background-clip: content-box, border-box !important;
}

.custom-axis-label {
  fill: #212d63 !important;
  opacity: 0.5;
}

.custom-dark-axis-label {
  fill: #ffffff !important;
  opacity: 0.3;
}
